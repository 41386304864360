'use client'

import type { ButtonWrapperType, CombinedCommonButtonProps, CombinedMouseEvent } from './Types'
import { forwardRef, type ForwardedRef, useCallback } from 'react'
import isFunction from 'lodash/isFunction'

/**
 * Internal implementation for switching between button and link
 * based on the passed type.
 */
const TypedButtonWrapper = forwardRef<ButtonWrapperType, CombinedCommonButtonProps>(({
  type = 'default',
  href,
  disabled,
  onClick: onOriginalClick,
  children,
  ...restProps
}, ref) => {
  const onClick = useCallback((e: CombinedMouseEvent) => {
    if (disabled) {
      e.preventDefault()
      return
    }
    if (!isFunction(onOriginalClick)) return

    onOriginalClick(e)
  }, [disabled, onOriginalClick])

  if (type === 'submit') {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        type="submit"
        ref={ref as ForwardedRef<HTMLButtonElement>}
        {...restProps}
      >
        {children}
      </button>
    )
  } else if (type === 'link') {
    return (
      <a
        onClick={onClick}
        href={href ?? '#'}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        {...restProps}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        type="button"
        ref={ref as ForwardedRef<HTMLButtonElement>}
        {...restProps}
      >
        {children}
      </button>
    )
  }
})
TypedButtonWrapper.displayName = 'TypedButtonWrapper'

export default TypedButtonWrapper
